
body {
	background-color: #F2F2F5;
	font-family: Verdana;
	font-size: 13px;
	margin: 0;
	padding: 0;
	top: 0;
	color: #313A42;
	background-image: url(layout/pw_pattern.png);
}



/* DEFAULTS ----------------------------------------------------- */
h1, h2, h3 { margin: 0 0 25px 0; padding: 0; }
h1 { font-size: 19px; }
h2 { font-size: 14px; }
img { margin: 0; padding: 0; border: 0; }
p { margin: 0; padding: 0; }
ul { margin: 0 0 0 10px;
	li { margin: 4px 0 4px 0; }
}
form { margin: 0; padding: 0; }
textarea { width: 450px; height: 150px; }
pre {
	font-family: Verdana;
	font-size: 13px;
	line-height: 25px;
	color: #384B5C;
	white-space: pre-wrap;       /* css-3 */
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
a { border: 0;
	color: #548FFF;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
		color: #548FFF;
	}
}
blockquote { font-style: italic;
	span { font-weight: bold; }
}



/* MAIN LAYOUT -------------------------------------------------- background-color: #2D4D6D;  */
div#top_bar { position: absolute; z-index: 5; width: 100%; height: 100px; top: 10px; border-bottom: 4px solid #2D4D6D; }
	div#top_container { position: relative; width: 950px; margin: 10px auto 0 auto;

		#therapist { position: relative; float: left; width: 480px; height: 90px;
			h1 { margin: 3px 0 0 0; padding: 0; font-size: 23px; font-weight: normal;
			}
			span { font-style: italic; }
			img { float: left; display: inline-block; width: 80px; margin-right: 10px; }
		}

		#nav { position: relative; float: right; width: 469px; height: 90px;
			ul {
				display: inline-block;
				list-style: none;
				width: inherit;
				margin: 30px 0 0 0;
				padding: 0;

				li { float: right; margin-left: 17px;
					a {
						color: #2D4D6D;
						font-size: 13px;
						font-weight: bold;
						line-height: 54px;
						padding: 0;
						text-decoration: none;
						&:hover { color: #183147; }
					}
				}
			}
		}

	}

div#content {
	position: relative;
	width: 950px;
	border: 0px solid #DDD;
	padding: 35px;
	margin: 110px auto 0 auto;
}


div#footer {
	position: relative;
	width: 950px;
	border-top: 1px solid #DDD;
	padding: 35px;
	margin: 0 auto 0 auto;
	text-align: center;
	font-size: 11px;
	a { color: Gray;
		font-weight: bold;
	}
}


/* 404, 500, 422 */
div.error_dialog { width: 600px;  margin: 80px auto 80px auto; text-align: center;
	h2 { margin-bottom: 10px; }
}

/* notifications */
#notice_msg { padding: 20px 20px 20px 0; margin: 0; color: green; }
#err_notice_msg { padding: 20px 20px 20px 0; margin: 0; color: red; }


/* errors */
#error_list { width: 400px; padding: 7px; padding-bottom: 0; margin-bottom: 0; color: red; }
.field_with_errors { padding: 1px; background-color: red; display: table; color: red; }





#contact-left { float: left; width: 600px; min-height: 400px; background-color: clear;
	h1 { margin-bottom: 30px; }
	p { margin-bottom: 35px; }
	ul { margin: 10px 0 30px 0; }
	form {
		input, textarea { font-size: 13px; }
		input { width: 400px; padding: 7px; border: 1px solid gray; }
		textarea { width: 450px; border: 1px solid gray; }
		#btn_submit { width: 100px; font-weight: bold; font-size: 14px; cursor: pointer; background-color: #2D4D6D; color: #FFF; }
	}
}
#contact-right { float: right; width: 190px; min-height: 500px; border-left: 1px solid gray; padding-left: 30px;
	span { font-weight: bold; line-height: 17px; }
}





/* HOME */
/* -------------------------------------------------------------------------------- */
#home-left {
	float: left;
	width: 650px;
	min-height: 300px;
	margin-right: 15px;
	p#tag_line { font-size: 14px; font-weight: bold; font-style: italic; }
}

#home-right {
	float: right;
	width: 230px;
	min-height: 320px;
	h1 { margin-bottom: 0; padding-bottom: 4px; }
	span { font-weight: bold; line-height: 17px; }
	img {
		width: 220px;
		border: 1px solid #FFF;
		padding: 2px;
	}
}


/* ABOUT */
/* -------------------------------------------------------------------------------- */
#about-left {
	float: right;
	width: 230px;
	min-height: 300px;
	margin-right: 15px;
	img {
		width: 220px;
		border: 1px solid #FFF;
		padding: 2px;
	}
	span { font-weight: bold; line-height: 17px; }
}

#about-right {
	float: left;
	width: 650px;
	min-height: 320px;
	background-color: clear;
	h1 { margin-bottom: 0; padding-bottom: 4px; }
	span { font-style: italic; line-height: 17px; }
}





/* RATES & INSURANCE */
/* -------------------------------------------------------------------------------- */
#ri-wrapper { width: 900px;
	h2 { margin: 0 0 3px 0; padding: 0; }
	p { margin-bottom: 30px; }
	ul { margin-bottom: 30px; }
}



/* PRIVACY POLICY */
/* -------------------------------------------------------------------------------- */
#pp-wrapper {
	h1 { margin-bottom: 4px; }
	p { margin-bottom: 30px; }
	ul { margin-top: 10px; }
}



/* COMMON QUESTIONS */
/* -------------------------------------------------------------------------------- */
#cq-wrapper {
	a { color: #00008B; }
	h2 { width: auto; margin-bottom: 4px;
		span { font-size: 10px; }
	}
	p { margin-bottom: 35px; }
	ol { margin-bottom: 50px; }
	ul { margin-bottom: 30px; }
}


/* SERVICES */
/* -------------------------------------------------------------------------------- */
#services-left { float: left; width: 700px; min-height: 400px;
	h1 { margin-bottom: 4px; }
	p { margin-bottom: 35px; }
	ul { margin: 10px 0 30px 0; }
}
#services-right { float: right; width: 190px; min-height: 400px; border-left: 1px solid gray;
	h2 { margin-bottom: 0; text-align: right; padding-bottom: 5px; border-bottom: 0px solid gray; }
	ul { margin-top: 7px; list-style-type: none;
		li { text-align: right; margin-top: 4px; }
	}
}



/* HELPFUL FORMS */
/* -------------------------------------------------------------------------------- */
#hf-wrapper {
	p { margin-bottom: 25px; }
	ul { margin-bottom: 30px; }
}